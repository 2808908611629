exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-carrito-vaciar-index-tsx": () => import("./../../../src/pages/carrito/vaciar/index.tsx" /* webpackChunkName: "component---src-pages-carrito-vaciar-index-tsx" */),
  "component---src-pages-login-aceptar-tyc-index-tsx": () => import("./../../../src/pages/login/aceptar-tyc/index.tsx" /* webpackChunkName: "component---src-pages-login-aceptar-tyc-index-tsx" */),
  "component---src-pages-login-confirmar-cuenta-index-tsx": () => import("./../../../src/pages/login/confirmar-cuenta/index.tsx" /* webpackChunkName: "component---src-pages-login-confirmar-cuenta-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-login-recuperar-cuenta-index-tsx": () => import("./../../../src/pages/login/recuperar-cuenta/index.tsx" /* webpackChunkName: "component---src-pages-login-recuperar-cuenta-index-tsx" */),
  "component---src-pages-login-reinicio-requerido-index-tsx": () => import("./../../../src/pages/login/reinicio-requerido/index.tsx" /* webpackChunkName: "component---src-pages-login-reinicio-requerido-index-tsx" */),
  "component---src-pages-mi-cuenta-index-tsx": () => import("./../../../src/pages/mi-cuenta/index.tsx" /* webpackChunkName: "component---src-pages-mi-cuenta-index-tsx" */),
  "component---src-pages-pagina-hoppy-holidays-2020-index-tsx": () => import("./../../../src/pages/pagina/hoppy-holidays-2020/index.tsx" /* webpackChunkName: "component---src-pages-pagina-hoppy-holidays-2020-index-tsx" */),
  "component---src-pages-productos-busqueda-index-tsx": () => import("./../../../src/pages/productos/busqueda/index.tsx" /* webpackChunkName: "component---src-pages-productos-busqueda-index-tsx" */),
  "component---src-pages-qr-caja-de-envio-index-tsx": () => import("./../../../src/pages/qr/caja-de-envio/index.tsx" /* webpackChunkName: "component---src-pages-qr-caja-de-envio-index-tsx" */),
  "component---src-templates-basic-page-template-v-2-index-tsx": () => import("./../../../src/templates/BasicPageTemplateV2/index.tsx" /* webpackChunkName: "component---src-templates-basic-page-template-v-2-index-tsx" */),
  "component---src-templates-brand-page-template-v-2-index-tsx": () => import("./../../../src/templates/BrandPageTemplateV2/index.tsx" /* webpackChunkName: "component---src-templates-brand-page-template-v-2-index-tsx" */),
  "component---src-templates-home-page-template-index-tsx": () => import("./../../../src/templates/HomePageTemplate/index.tsx" /* webpackChunkName: "component---src-templates-home-page-template-index-tsx" */)
}

